.card-container {
  display: flex;
  justify-content: space-evenly;

  .card {
    border-radius: 20px;
    border: 1px solid grey;
    width: 50%;
    padding: 1rem;
    margin: 0.5rem;
  }
}
