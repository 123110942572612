.container {
  display: grid;
  padding-top: 70px;
  grid-template-columns: 25% 25% 25% 25%;
  .img {
    height: 65vh;
    width: 24vw;
    margin: 0.5rem;
    img {
      width: 100%;
      height: 90%;
    }
    h3 {
      margin-top: 0;
    }
  }
}
.load-more {
  display: flex;
  margin-bottom: 0.5rem;

  width: 200px;
  height: 60px;
  background-color: #ff3733;
  align-items: center;
  justify-content: center;
  position: relative;
  button {
    color: white;
    font-weight: bold;
    font-size: large;
  }
}

.load-more::before {
  content: "";

  border-style: solid;
  border-width: 0px 20px 20px 0px;
  border-color: transparent rgb(255, 255, 255) transparent transparent;
  position: absolute;
  top: 0px;
  right: 0;
}

.load-more::after {
  content: "";
  border-style: solid;
  border-width: 0px 20px 20px 0px;
  border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) white
    rgba(255, 255, 255, 0);
  position: absolute;
  top: 40px;
  left: 0;
}
